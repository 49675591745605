import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField as MuiTextField,
  Typography,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {Fragment, useRef, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {Control, useFieldArray, useForm} from 'react-hook-form';
import RichTextEditor from 'react-rte';
import SignaturePad from 'react-signature-canvas';
import * as yup from 'yup';
import {useNotification} from '../../../hooks';
import {UploadDocument} from '../../../util';
import {RadioGroup} from '../../RadioGroup';
import {ResponseWizardSummary} from './ResponseWizardSummary';

const getCanvasProps = (
  canvas: HTMLCanvasElement
): {height: number; width: number; pixels: number} => {
  const result = {
    height: canvas.height,
    width: canvas.width,
    pixels: 0,
  };

  const canvasContext = canvas.getContext('2d');

  if (!canvasContext) {
    return result;
  }

  const imgData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);

  for (let i = 0; i < imgData.data.length; i += 4) {
    if (imgData.data[i + 3] > 0) {
      result.pixels++;
    }
  }

  return result;
};

enum WizardSteps {
  Response,
  SupportingDocuments,
  DetailedRebuttal,
  Review,
}

const steps = ['Dispute Response', 'Supporting Documents', 'Detailed Response', 'Review'];

export interface FileToUpload {
  file: File;
  uploadDocument: UploadDocument | null;
}

export type ResponseWizardFormValues = {
  response: string;
  filesToUpload?: FileToUpload[];
  rebuttal?: string;
  signature?: string;
};

interface UploadedFileProps {
  fileToUpload: FileToUpload;
  control: Control<ResponseWizardFormValues, ResponseWizardFormValues>;
  index: number;
  removeItem: (index: number) => void;
  disabled: boolean;
  errors: any;
}

const UploadedFile = ({
  fileToUpload,
  control,
  index,
  removeItem,
  disabled,
  errors,
}: UploadedFileProps) => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={1} sx={{width: '100%'}}>
        <MuiTextField
          variant="outlined"
          margin="normal"
          value={fileToUpload.file.name}
          label="File Name"
          disabled
          fullWidth
        />
        <IconButton
          disabled={disabled}
          onClick={() => removeItem(index)}
          sx={{
            height: '24px',
            marginTop: 'auto!important',
            marginBottom: 'auto!important',
            p: 0,
          }}
          color="secondary"
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Grid>
  );
};

const schema = yup.object().shape({
  signature: yup.string().required('Signature is required'),
});

interface Props {
  onClose: () => void;
  onSubmit: (data: ResponseWizardFormValues) => Promise<void>;
}

// react-drag-drop-files and react-signature-canvas doesn't work without providing class name as property
const useStyles = makeStyles(() =>
  createStyles({
    fileUploader: {
      maxWidth: '100%!important',
    },
    signaturePad: {
      width: '100%',
      height: '100%',
    },
  })
);

export const ResponseWizard = ({onClose, onSubmit}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const showNotification = useNotification();
  const [activeStep, setActiveStep] = useState(WizardSteps.Response);
  const [isSaving, setIsSaving] = useState(false);
  const [rebuttalMessage, setRebuttalMessage] = useState(RichTextEditor.createEmptyValue());
  const [acceptDebit, setAcceptDebit] = useState<boolean>(false);

  const signatureRef = useRef<SignaturePad>() as React.MutableRefObject<any>;

  const {formState, getValues, control, watch, trigger, setValue} =
    useForm<ResponseWizardFormValues>({
      defaultValues: {
        response: 'true',
        filesToUpload: [],
        rebuttal: '',
      },
      resolver: yupResolver(schema),
    });

  const {fields, append, remove} = useFieldArray({
    name: 'filesToUpload',
    control,
  });

  const {errors} = formState;

  const watchResponse = watch('response');

  const getResponseText = (resp: string) =>
    resp === 'true'
      ? "I wish to provide documentation to support reversing this dispute (this decision is solely made by the cardholder's bank)"
      : 'I accept the debit to my account and no further action is needed';

  const getNextButtonLabel = () => (activeStep === WizardSteps.Review ? 'Submit Response' : 'Next');

  const handleNextButtonClick = async () => {
    switch (activeStep) {
      case WizardSteps.Response: {
        if (watchResponse === 'true') {
          setAcceptDebit(false);
          setActiveStep(WizardSteps.SupportingDocuments);
        } else {
          setAcceptDebit(true);
          setActiveStep(WizardSteps.Review);
        }
        break;
      }
      case WizardSteps.SupportingDocuments: {
        setActiveStep(WizardSteps.DetailedRebuttal);
        break;
      }
      case WizardSteps.DetailedRebuttal: {
        setValue('rebuttal', rebuttalMessage.toString('html'));
        setActiveStep(WizardSteps.Review);
        break;
      }
      case WizardSteps.Review: {
        setIsSaving(true);
        // check signature:
        if (!signatureRef || signatureRef.current.isEmpty()) {
          setValue('signature', undefined);
          trigger('signature');
          return;
        }

        const canvasProps = getCanvasProps(signatureRef.current.getTrimmedCanvas());
        const fillRatio = canvasProps.pixels / (canvasProps.height * canvasProps.width);

        // there are 2 places with same logic
        if (canvasProps.height < 50 || canvasProps.width < 100 || fillRatio < 0.015) {
          setValue('signature', undefined);
          trigger('signature');
          return;
        }

        const dataUrl = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

        setValue('signature', dataUrl);

        const data = getValues() as ResponseWizardFormValues;

        // clean up response:
        if (data.response === 'false') {
          delete data.rebuttal;
          delete data.filesToUpload;
        }

        // submit data:
        await onSubmit(data);
        setIsSaving(false);
        break;
      }
      default:
    }
  };

  const handleChangeFiles = (filesToAdd: Array<File>) => {
    const newFilesToUpload = Object.values(filesToAdd).map(file => {
      return {
        file,
        uploadDocument: null,
      } as FileToUpload;
    });
    append(newFilesToUpload);
  };

  const removeFile = (index: number) => {
    remove(index);
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="create-dialog-title" maxWidth={'lg'}>
      <DialogTitle id="create-dialog-title">Response Wizard</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{padding: 2}}>
          <Step key={WizardSteps.Response}>
            <StepLabel>{steps[WizardSteps.Response]}</StepLabel>
          </Step>
          <Step key={WizardSteps.SupportingDocuments}>
            <StepLabel>{steps[WizardSteps.SupportingDocuments]}</StepLabel>
          </Step>
          <Step key={WizardSteps.DetailedRebuttal}>
            <StepLabel>{steps[WizardSteps.DetailedRebuttal]}</StepLabel>
          </Step>
          <Step key={WizardSteps.Review}>
            <StepLabel>{steps[WizardSteps.Review]}</StepLabel>
          </Step>
        </Stepper>
        {activeStep === WizardSteps.Response && (
          <Fade in={activeStep === WizardSteps.Response} timeout={{enter: 1000, exit: 0}}>
            <Box
              sx={{
                minHeight: 200,
                maxWidth: 1100,
              }}
            >
              <RadioGroup
                name="response"
                label=""
                defaultValue={'true'}
                errors={errors}
                control={control}
              >
                <FormControlLabel
                  key={'addResponse'}
                  value={'true'}
                  control={<Radio />}
                  label={<Typography>{getResponseText('true')}</Typography>}
                />
                <FormControlLabel
                  key={'acceptDebit'}
                  value={'false'}
                  control={<Radio />}
                  label={<Typography>{getResponseText('false')}</Typography>}
                />
              </RadioGroup>
            </Box>
          </Fade>
        )}
        {activeStep === WizardSteps.SupportingDocuments && (
          <Fade
            in={activeStep === WizardSteps.SupportingDocuments}
            timeout={{enter: 1000, exit: 0}}
          >
            <Box
              sx={{
                marginTop: 2,
                minHeight: 200,
                minWidth: 1100,
              }}
            >
              <Typography sx={{marginBottom: 2}}>
                Please upload any supporting documentation to refute the cardholder's dispute of
                this transaction
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FileUploader
                    multiple={true}
                    handleChange={handleChangeFiles}
                    name="file"
                    classes={classes.fileUploader}
                    types={['tif', 'tiff', 'jpg', 'jpeg', 'png', 'pdf']}
                    disabled={isSaving}
                  />
                </Grid>
                {fields.map((field, index) => {
                  return (
                    <UploadedFile
                      key={field.id}
                      fileToUpload={field as FileToUpload}
                      control={control}
                      index={index}
                      removeItem={removeFile}
                      disabled={isSaving}
                      errors={errors}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Fade>
        )}
        {activeStep === WizardSteps.DetailedRebuttal && (
          <Fade in={activeStep === WizardSteps.DetailedRebuttal} timeout={{enter: 1000, exit: 0}}>
            <Box
              sx={{
                marginTop: 2,
                minHeight: 200,
                minWidth: 1100,
              }}
            >
              <Typography sx={{marginBottom: 2}}>
                Please supply a detailed response to the cardholder’s dispute of this transaction.
                If a full or partial credit has been issued, please explain.
              </Typography>
              <RichTextEditor
                autoFocus
                value={rebuttalMessage}
                onChange={setRebuttalMessage}
                placeholder="Leave a comment"
                editorStyle={{
                  minHeight: '100px',
                  fontFamily: 'Rubik, sans-serif',
                }}
              />
            </Box>
          </Fade>
        )}
        {activeStep === WizardSteps.Review && (
          <Fade in={activeStep === WizardSteps.Review} timeout={{enter: 1000, exit: 0}}>
            <Box
              sx={{
                minHeight: 200,
                minWidth: 1100,
              }}
            >
              <Box
                sx={{
                  marginTop: 2,
                  minHeight: 200,
                  minWidth: 1100,
                }}
              >
                <ResponseWizardSummary
                  title={steps[WizardSteps.Response]}
                  handleEditClick={() => setActiveStep(WizardSteps.Response)}
                  children={<Typography>{getResponseText(watchResponse)}</Typography>}
                />
                {!acceptDebit && (
                  <ResponseWizardSummary
                    title={steps[WizardSteps.SupportingDocuments]}
                    handleEditClick={() => setActiveStep(WizardSteps.SupportingDocuments)}
                    children={
                      <Fragment>
                        {fields.map((field, index) => {
                          return (
                            <UploadedFile
                              key={field.id}
                              fileToUpload={field as FileToUpload}
                              control={control}
                              index={index}
                              removeItem={removeFile}
                              disabled={true}
                              errors={errors}
                            />
                          );
                        })}
                      </Fragment>
                    }
                  />
                )}
                {!acceptDebit && (
                  <ResponseWizardSummary
                    title={steps[WizardSteps.DetailedRebuttal]}
                    handleEditClick={() => setActiveStep(WizardSteps.DetailedRebuttal)}
                    children={
                      <RichTextEditor
                        value={rebuttalMessage}
                        onChange={setRebuttalMessage}
                        disabled={true}
                        placeholder=""
                        editorStyle={{
                          minHeight: '100px',
                          fontFamily: 'Rubik, sans-serif',
                        }}
                      />
                    }
                  />
                )}
                <Box sx={{marginTop: 2}}>
                  <Grid item xs={12}>
                    <Typography>Electronic Signature</Typography>
                  </Grid>
                  {errors && errors.signature && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errors.signature.message}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="caption" gutterBottom>
                      Draw your signature with your mouse or finger:
                    </Typography>
                    <Box
                      sx={{
                        border: 'solid 1px rgba(0, 0, 0, 0.87)',
                        borderRadius: 1,
                        backgroundColor: '#ffffff',
                        width: '50%',
                        height: 150,
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      <SignaturePad
                        canvasProps={{className: classes.signaturePad}}
                        ref={signatureRef}
                      />
                    </Box>
                    <Typography variant="caption" gutterBottom>
                      <strong>
                        Please use the entire signature field to make better quality signature
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="outlined" onClick={() => signatureRef.current.clear()}>
                      Clear Signature
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Fade>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {activeStep > WizardSteps.Response && (
          <Button
            onClick={() =>
              acceptDebit ? setActiveStep(WizardSteps.Response) : setActiveStep(activeStep - 1)
            }
          >
            Back
          </Button>
        )}
        <Button disabled={isSaving} onClick={handleNextButtonClick}>
          {getNextButtonLabel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import PhoneNumber from 'awesome-phonenumber';
import {DocumentBase} from '.';
import {Dispositions, PayTo} from '../shared';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type SubmittedPageSettings = {
  title?: string;
  subTitle?: string;
  footerText?: string;
};

export type Group = DocumentBase & {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  logoUrl: string;
  logoUpdatedAt?: UniversalTimestamp;
  logoThumbnailUrl: string;
  termsUrl: string;
  hexColorPrimary: string;
  hexColorTextPrimary: string;
  appDomain: string;
  portalDomain: string;
  createdAt: UniversalTimestamp;
  merchantUids: string[];
  backgroundUrl?: string;
  ownerAgentUid?: string;
  deleted?: boolean;
  applicationSettings?: {
    enableRegistration?: boolean;
    onCompleteDisposition?: Dispositions;
    enableEquipmentFileDialog?: boolean;
    enableMerchantsToAddNewAccounts?: boolean;
    submittedPage?: SubmittedPageSettings;
    dobSsnTaxIdAndBankInfoNotRequired?: boolean;
    enableMFA?: boolean;
    enablePersonaIdVerification?: boolean;
    rentalPaymentTypeEnabled?: boolean;
    freePlacementPaymentTypeEnabled?: boolean;
    billToResidualsPaymentTypeEnabled?: boolean;
    enableAgentProfileCreation?: boolean;
    payTo?: PayTo;
  };
  institutionServiceProvider: 'Plaid' | 'Finicity';
  finicityExperienceId?: string | null;
  isActive: boolean;
};

export type GroupView = ViewBase<Group>;

export const selectGroupView: ViewSelector<GroupView, Group> = snapshot => {
  const data = snapshot.data();
  const group: GroupView = {
    ...defaultViewSelector(snapshot),
    phone: data.phone ? new PhoneNumber(data.phone, 'US').getNumber('national') : data.phone,
  };
  return group as GroupView;
};
